/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Lowfat es visor de imágenes muy especial, podemos manejar las fotos como si las manejáramos con las manos, ampliarlas y reducirlas con sencillos gestos y muchas mas opciones. Aquí dejo un video para que veais todas sus posibilidades."), "\n", React.createElement(_components.p, null, "[youtube=http://www.youtube.com/watch?v=GkrM4ymkiDo]"), "\n", React.createElement(_components.p, null, "El programa me lo he bajado con estos comandos:"), "\n", React.createElement(_components.p, null, "$ aptitude install git git-core libglib2.0-dev libgtk2.0-dev"), "\n", React.createElement(_components.p, null, "$ git clone git://people.freedesktop.org/~macslow/lowfat"), "\n", React.createElement(_components.p, null, "Despues se suponia que lo tenia que compilar, para esto he tenido muchos problemas y no lo he conseguido, pero no hace falta compilarlo para que el programa funcione. Simplemente pinchando en start-lowfat.sh ya se abre el programa, asi que he renombrado la carpeta con el nombre de .lowfat para ocultarla y he echo un enlace a la aplicación."), "\n", React.createElement(_components.p, null, "Por ultimo para manejar lowfat se utilizan estos comandos."), "\n", React.createElement(_components.p, null, "F1 - Modo normal F2 - Modo caótico F3 - Modo simple a - Ordenar por aspecto s - Ordenar por tamaño d - Ordenar por fecha Space - Pila q, esc - Salir i - Información o - Tamaño original l - Cargar ficheros del - Quitar fichero r - Resetear posición/movimiento (shift) up/right - Rotar a la derecha (shift) down/left - Rotar a la izquierda"), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
